import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import { BillingLinks } from 'pages/settings/BillingSection'
import React from 'react'
import styled from 'styled-components'
import { AppStore, T } from 'utils'

interface AddonInfoProps {}

const UpgradeModalAddon: React.FC<AddonInfoProps> = ({}) => {
  return (
    <UpgradeModalContainer>
      <UpperSectionTitle>
        <UpgradeTitle>{T.billing.addOn.caption}</UpgradeTitle>

        <UrlsContainer>
          <Link href={BillingLinks.capabilities} target="_blank">
            {T.billing.matchPlans}
          </Link>
          <Link href={BillingLinks.faq} target="_blank">
            {T.billing.faq}
          </Link>
        </UrlsContainer>
      </UpperSectionTitle>

      {/* Addon Section */}
      {/* <AddonContainer>
        <AddonSection>
          <Title>{T.billing.addOn.addonTitle}</Title>
          <Description>{T.billing.addOn.addonDescription}</Description>
        </AddonSection>

        <FeatureSection>
          <AddonSubSection>
            <FeatureTitle>
              {T.billing.addOn.floraTitle}

              <Icons.magic fill={AppStore.theme.o.black} width={28} height={28} style={{ marginLeft: '8px' }} />
            </FeatureTitle>
            <FeatureDescription>{T.billing.addOn.floraDescription}</FeatureDescription>
          </AddonSubSection>

          <ComingSoon>{T.billing.comingSoon}</ComingSoon>
        </FeatureSection>
      </AddonContainer> */}
    </UpgradeModalContainer>
  )
}

export default UpgradeModalAddon

// ------------------ CSS STYLES ------------------------

const UpperSectionTitle = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
`

const UrlsContainer = styled(Box)`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

const Link = styled.a`
  font-size: 16px;
  font-weight: 400;
  color: ${() => AppStore.theme.o.textDarkGrey};
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: ${() => AppStore.theme.o.black};
  }
`

const UpgradeModalContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: left;
`

const UpgradeTitle = styled.h2`
  font-size: 21px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.primary};
  margin-bottom: 0;
`

const AddonContainer = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 8px 8px 24px;
  background-color: ${() => AppStore.theme.o.background};
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
`

const AddonSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 0;
  align-self: center;
`

const Title = styled.h2`
  font-size: 21px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.black};
  margin-bottom: 11px;
`

const Description = styled.p`
  font-size: 14px;
  color: ${() => AppStore.theme.o.textDarkGrey};
  line-height: 1.5;
`

const FeatureSection = styled.div`
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  background-color: ${() => AppStore.theme.o.lightestGrey};
`

const FeatureTitle = styled.h1`
  font-size: 32px;
  font-weight: 600;
  color: ${() => AppStore.theme.o.black};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

const FeatureDescription = styled.p`
  font-size: 14px;
  color: ${() => AppStore.theme.o.textDarkGrey};
  line-height: 1.5;
`

const ComingSoon = styled.p`
  font-size: 14px;
  color: ${() => AppStore.theme.o.textDarkGrey};
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  align-self: center;
  max-height: 40px;
  padding: 8px 48px 8px 48px;
  font-weight: 600;
  border-radius: 4px;
  margin-left: 12px;
  border-radius: 14px;
`

const AddonSubSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
