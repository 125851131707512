import PostV2 from 'api/PostV2'
import { Tracker } from 'api/Tracker'
import { hasTotMinutesPassed } from 'api/Utils'
import { Icons } from 'components/Icons'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { rgb } from 'pdf-lib'
import { useCalendarStore } from 'stores/CalendarStore'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { useModalNavigationStore } from 'stores/ModalNavigationStore'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, showError, showInfo, showSuccess, T } from 'utils'
import { getMediaFileFormats, getMixpanelPostDeletedData, getSocialExtras, TRACKEREVENTS } from 'utils/eventsTracker'
import { MixpanelPostRecycledData } from 'utils/eventsTracker/posts/postInterfaces'
import { updatePost } from 'utils/PostUtils/Post'
import { onClickSocialCard, TSocialCardValue } from 'utils/PostUtils/SocialPost'

/* ----------------- METHODS  */

/**
 * Funzione per eliminare le proprietà di un post che non servono per il passaggio da calendario a create post section
 * @param post IPostVariant
 * @param resetDate Parametro opzionale per definire se settare la data ad undefined
 * @param resetId Parametro opzionale per definire se resettare l'id
 * @returns Un post con le proprietà eliminate
 */
export const deletePostProperties = (post: IPostVariant, resetDate?: boolean, resetId?: boolean) => {
  const newPost = JSON.parse(JSON.stringify(post))

  delete newPost.data.status
  delete newPost.data.statusMessage
  delete newPost.data._id
  delete newPost.creationDate

  if (resetDate) newPost.data.date = undefined
  if (resetId) newPost._id = undefined

  return newPost
}

/**
 * Funzione per validare la data di un post, se la data è nel passato la elimina (setta ad undefined) altrimenti se la data è nel futuro la mantiene.
 * @param post IPostVariant
 * @returns Un post con il valore di date modificato
 */
export const validateDatePost = (post: IPostVariant) => {
  const newPost = JSON.parse(JSON.stringify(post))

  const reusablePostDate = newPost.publishDate
  const now = new Date().toISOString()

  // Se la data è nel passato la elimina altrimenti la mantiene
  // @ts-ignore
  if (reusablePostDate < now) {
    return {
      ...newPost,
      data: {
        ...post.data,
        date: undefined,
      },
    }
  } else {
    return {
      ...newPost,
      data: {
        ...post.data,
        date: reusablePostDate,
      },
    }
  }
}

/**
 * Funzione che serve per controllare se un post è già stato aggiunto al create post
 * @param post IPostVariant
 * @returns TSocialCardValue
 */
export const checkIfPostIsAlreadyAdded = (post: IPostVariant): TSocialCardValue => {
  return onClickSocialCard(post.info?.account, undefined, post.variantId, post._id)
}

export const duplicatePostMixpanel = (post: IPostVariant) => {
  const postRecycledDataMixpanel: MixpanelPostRecycledData = {
    social_channel: post.info.account.provider,
    has_caption: post.data.text.length > 0,
    post_status: post.data.status,
    file_format: getMediaFileFormats(post),
    has_social_extras: Object.keys(getSocialExtras(post)).length > 0,
  }

  Tracker.trackEvent(TRACKEREVENTS.POST_RECYCLED, postRecycledDataMixpanel)
}

/**
 * Funzione di riutilizzo di un post a livello dei suoi dati, resetta la sua data e il suo _id e lo aggiunge al create post
 * @param post IPostVariant
 */
export const duplicatePost = async (post: IPostVariant) => {
  // Elimina le proprietà che non servono per il riutilizzo
  const workingPost = deletePostProperties(post, true, true)
  await useCreatePostStore.getState().clearPost()

  // Controlla che il post non sia già aggiunto al create post
  const checkIfAdded = checkIfPostIsAlreadyAdded(workingPost)
  if (checkIfAdded === 'alreadyAdded') return

  // Aggiorna la lista dei post nel create post
  updatePost(workingPost.variantId, workingPost.data)

  // Chiude tutte le modali
  AppStore.closeAllModal()

  // Mix panel
  duplicatePostMixpanel(post)

  // Navigate
  useModalNavigationStore.getState().setNavigatePage('/post/preview/basic')
}

export const editPostMixpanel = (post: IPostVariant) => {
  const postRecycledDataMixpanel: MixpanelPostRecycledData = {
    social_channel: post.info.account.provider,
    file_format: getMediaFileFormats(post),
    has_caption: post.data.text.length > 0,
    has_social_extras: Object.keys(getSocialExtras(post)).length > 0,
    post_status: post.data.status,
  }

  Tracker.trackEvent(TRACKEREVENTS.POST_EDITED, postRecycledDataMixpanel)
}

/**
 * Funzione di edit del post, aggiunge il post al create post e mantiene la sua data (se non è nel passato)
 * @param post IPostVariant
 */
export const editPost = async (post: IPostVariant) => {
  // Check permission to edit post
  const check = await AppStore.checkPermission('edit_post')
  if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.editPost}`)

  // Controlla che la data del post sia valida
  const checkDatePost = validateDatePost(post)

  // Elimina le proprietà che non servono per il riutilizzo
  const workingPost = deletePostProperties(checkDatePost)

  // Controlla che il post non sia già aggiunto al create post
  const checkIfAdded = checkIfPostIsAlreadyAdded(workingPost)
  if (checkIfAdded === 'alreadyAdded') return

  // Aggiorna la lista dei post nel create post
  updatePost(workingPost.variantId, workingPost.data)

  // Chiude tutte le modali
  AppStore.closeAllModal()

  // Mix panel
  editPostMixpanel(post)

  // Navigate
  useModalNavigationStore.getState().setNavigatePage('/post/preview/basic')
}

/**
 * Funzione per eliminare un post specifico
 * @param post IPostVariant
 */
export const deletePost = async (post: IPostVariant) => {
  if (!post._id) return showError(T.error.notEsistente)

  try {
    // Chiamata per eliminare il post
    const res = await PostV2.deletePosts([post._id])

    // Chiude tutte le modali
    AppStore.closeAllModal()

    showSuccess(T.success.eliminationSuccess)

    useCalendarStore.getState().forceUpdate()

    Tracker.trackEvent(TRACKEREVENTS.POST_DELETED, getMixpanelPostDeletedData(post, false))
  } catch (error) {
    showError(error)
  }
}

/**
 * Funzione per aprire un post
 * @param post IPostVariant
 */
export const goToThePost = (post: IPostVariant) => {
  if (!post.info.linkRef) {
    const hasTenMinutesPassed = hasTotMinutesPassed(post.publishDate!, 10)

    return showError(hasTenMinutesPassed ? T.error.urlNotFound : T.error.urlNotYetAvailable)
  }

  window.open(post.info.linkRef!, '_blank')
}

const addClassname = (zoom) => {
  const style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML = `.ant-select-dropdown {
      transform: scale(${zoom}%);
    }

    .ant-popover {
      transform: scale(${zoom}%);
      visibility: hidden;
    }
    `
  document.head.appendChild(style)
}

const downloadImage = async (url: string): Promise<string | undefined> => {
  try {
    return await PostV2.downloadImage(url)
  } catch (error) {
    console.error('Errore durante il download dell\'immagine:', error)
    return undefined
  }
}

// ------------DOWNLOAD
export const downloadPreviewPost = async (postId: string) => {
  const element = document.getElementById('post_preview_modal')
  
  if (element) {
    let width = 0
    let height = 0
    const imageUrl = element.querySelector('img.metadata-img')?.getAttribute('src')
    const imageBase64 = imageUrl ? await downloadImage(imageUrl as string) : undefined // Downloading the image
    
    html2canvas(element, {
      onclone: (document) => {
        // Cloning the document to avoid users seeing zoomed in elements
        const clonedDocument = document as Document
        const mainContainer = document.getElementById('opacity_container_preview_post')
        if (mainContainer) {
          mainContainer.remove()
        }
        const clonedElement = clonedDocument.getElementById('post_preview_modal')

        // Update image src with the downloaded image to prevent CORS issues
        if (imageBase64) {
          clonedElement?.querySelector('img.metadata-img')!.setAttribute('src', imageBase64)
        }
        clonedElement!.style.cssText =
          clonedElement!.style.cssText +
          `\nborder-radius: 0px !important; \nbackground-color: ${AppStore.theme.o.surface} !important;`

        if (clonedElement) {
          // Applying zoom to the cloned document
          const rootElement = clonedDocument.getElementById('root')!
          const zoom = 100
          rootElement.style.cssText = `
            transform: scale(${zoom}%, ${zoom}%);
            transform-origin: left top;
            max-width: ${100 * (100 / zoom)}vw;
            min-width: ${100 * (100 / zoom)}vw;
            max-height: ${100 * (100 / zoom)}vh;
            min-height: ${100 * (100 / zoom)}vh;
          `
          addClassname(zoom)
          const rect = clonedElement.getBoundingClientRect()
          width = rect.width
          height = rect.height
        }
      },
      useCORS: true,
      allowTaint: true,
    })
      .then((canvas) => {
        // Creating a temporary canvas to draw the screenshot
        const tempCanvas = document.createElement('canvas')
        tempCanvas.width = width
        tempCanvas.height = height
        const tempCtx = tempCanvas.getContext('2d')

        tempCtx?.drawImage(canvas, 0, 0, width, height)

        // Converting the screenshot to a base64 image
        const imgData = tempCanvas.toDataURL('image/png')

        // Downloading the image as a PDF
        if (process.env.REACT_APP_POST_DOWNLOAD_TYPE === 'pdf') {
          const pdf = new jsPDF('p', 'pt', 'a4'),
            font = 'aktiv-grotesk'
          const x = (pdf.internal.pageSize.getWidth() - width * 0.6) / 2
          const y = (pdf.internal.pageSize.getHeight() - height * 0.6) / 2

          pdf.addImage(imgData, 'PNG', x, y, width * 0.6, height * 0.6)

          pdf.save(`post_${postId}.pdf`)
        } else {
          // Downloading the image as a PNG
          const link = document.createElement('a')
          link.href = imgData
          link.download = `post_${postId}.png`

          link.style.display = 'none'
          document.body.appendChild(link)

          link.click()
        }
      })
      .catch((error) => {
        console.error('Errore durante la cattura dello screenshot o la generazione del PDF:', error)
      })
  }
}

export const hexToRgb = (hex: string) => {
  return rgb(
    parseInt(hex.slice(1, 3), 16) / 255,
    parseInt(hex.slice(3, 5), 16) / 255,
    parseInt(hex.slice(5, 7), 16) / 255
  )
}

export type TIconsName = 'download' | 'delete' | 'edit' | 'labels' | 'reuse' | 'goToPost'

export interface IPossibleIcons {
  id: TIconsName
  icon: JSX.Element
  label: string
  color: string
  background: string
  visibilityCheck: {
    planned: boolean
    error: boolean
    published: boolean
    draft: boolean
  }
}

export const possibleIcons: IPossibleIcons[] = [
  {
    id: 'delete',
    icon: <Icons.trashCan />,
    label: T.singleWord.delete,
    color: AppStore.theme.o.on_error_container,
    background: AppStore.theme.o.error_container,
    visibilityCheck: {
      planned: true,
      error: true,
      published: true,
      draft: true,
    },
  },
  {
    id: 'edit',
    icon: <Icons.modifyIcon />,
    label: T.singleWord.edit,
    color: AppStore.theme.o.on_secondary_container,
    background: AppStore.theme.o.secondary_container,
    visibilityCheck: {
      planned: true,
      error: false,
      published: false,
      draft: true,
    },
  },
  {
    id: 'reuse',
    icon: <Icons.reuse />,
    label: T.singleWord.reuse,
    color: AppStore.theme.o.black,
    background: AppStore.theme.o.lightGrey,
    visibilityCheck: {
      planned: true,
      error: true,
      published: true,
      draft: true,
    },
  },
  {
    id: 'download',
    icon: <Icons.downloadArrowDown />,
    label: T.singleWord.download,
    color: AppStore.theme.o.black,
    background: AppStore.theme.o.lightGrey,
    visibilityCheck: {
      planned: true,
      error: true,
      published: true,
      draft: true,
    },
  },
  {
    id: 'labels',
    icon: <Icons.label />,
    label: T.singleWord.labels,
    color: AppStore.theme.o.on_tertiary_container,
    background: AppStore.theme.o.tertiary_container,
    visibilityCheck: {
      planned: true,
      error: true,
      published: true,
      draft: true,
    },
  },
  {
    id: 'goToPost',
    icon: <Icons.goToPost />,
    label: T.singleWord.goToPost,
    color: AppStore.theme.o.black,
    background: AppStore.theme.o.lightGrey,
    visibilityCheck: {
      planned: false,
      error: false,
      published: true,
      draft: false,
    },
  },
]
