import { ApiResponse, create } from 'apisauce'
import config from 'config'
import qs from 'qs'
import { T } from 'utils'

/**
 * Will throw an exception if server returned an error
 * @param res
 */
export const responseErrorCheck = <T = any>(res: ApiResponse<T, any>) => {
  if (res?.status === 0 || res?.problem === 'UNKNOWN_ERROR') {
    throw new Error(T.error.serverError)
  }

  if (res && (res.status === 200 || res.status === 204 || res.status === 201)) {
    return res.data as T
  }

  if (res.status === 401 && res.config?.url === '/login') {
    // showNotification('Errore', 'Credenziali errate', 'error')
    throw new Error(T.error.credentialError)
  }

  if (res.status === 401) {
    // showNotification('Errore', 'Non autorizzato', 'error')
    //TODO RIMUOVI TOKEN E REFRESHA PAGINA PER ANDARE IN LOGIN
    throw new Error(T.error.notAuthorized)
  }

  if (res.status === 403) {
    throw new Error(T.error.notHavePermiseToGetRecord)
  }

  if (res.status === 404) {
    throw new Error(res?.data?.message ?? '404' + T.error.error)
  }

  if (res.status === 406) {
    throw new Error(res?.data?.message ?? '406')
  }

  if (res?.status && res?.status >= 400) {
    const errorHasCode = res.data?.errorCode !== undefined || res.data?.messageCode !== undefined
    throw new Error(
      `${res.data?.errorCode ?? res.data?.messageCode ?? ''}${errorHasCode ? ': ' : ''}${res.data?.message}` ??
        T.error.serverError ??
        ''
    )
  }

  if (res.data && res.data.error?.message) {
    // showNotification(res.data.error.message, 'danger')
    throw new Error(T.error.error)
  }

  if (!res || !res.data) {
    // showNotification('Malformed Response from server', 'danger')
    throw new Error(T.error.malformedResponseServer)
  }

  if (res.problem) {
    // showNotification(res.data?.message || res.problem, 'danger')
    throw new Error(T.error.error)
  }

  return res.data
}

/**
 * Apisauce params serializer adapted to TMR API
 */
const paramsSerializer = (params: any) =>
  qs.stringify(params, {
    // TMR API handles multiple values with format ?code=A&code=B, but default serializer
    // uses format ?code[]=A&code[]=B so override with option arrayFormat: repeat
    arrayFormat: 'repeat',
    // TMR API date format is unix timestamp in milliseconds
    // Note: this will not usually be used as date params are converted to timestamp
    // as soon as they're parsed from user input
    serializeDate: (Date) => Date.getTime().toString(),
  })

const api = create({
  baseURL: config.endpoint,
  timeout: 30000,
  headers: {
    accept: '*/*',
    'Content-Type': 'application/json',
  },
  paramsSerializer,
})

const loginApi = create({
  baseURL: config.endpoint,
  timeout: 30000,
  headers: {
    accept: '*/*',
    'Content-Type': 'application/json',
  },
  paramsSerializer,
})

api.axiosInstance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  (error) => {
    // if on login page respond with 401
    if (error.request.responseURL.includes('/login')) {
      return Promise.reject(error)
    }
    if (error.response.status === 401) {
      // alert('session expired')
      // DeviceEventEmitter.emit('AccessTokenExpired')
    } else {
      return Promise.reject(error)
    }
  }
)

// export const translateErrorMessage = (message: string) => {
//   return message
// }

// export default create({
//   baseURL: 'https://localhost', // this is useless, each resource will define the baseUrl
//   timeout: 30000,
// })

export { api, loginApi }
