import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import Utils from 'api/Utils'
import { Button, Icons } from 'components'
import { openEditor } from 'components/Modal/ModalManager'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { CrelloResponse, Media } from 'types'
import { IPostData } from 'types/PostInterface'
import { AppStore, showInfo, T } from 'utils'
import { getEditorFileSaveData, TRACKEREVENTS } from 'utils/eventsTracker'
import { updateActive } from 'utils/PostUtils/Post'

interface Props {
  onSave?: () => void
  isModal?: boolean // se è una modale allora permette di salvare il media anche nel post attuale, altrimenti ci troviamo in una pagina di navigazione
}
export default function EditorButton(props: Props) {
  const onCrelloSave = async (crelloResponse: CrelloResponse) => {
    try {
      useCreatePostStore.getState().setMediaLoading(true)
      const { activePost } = useCreatePostStore.getState()
      let activePostMedias: Media[] | undefined = undefined

      const path = `${AppStore.workspace._id}/`

      const fileUrl = crelloResponse.url
      const fileExtension = fileUrl.split('.').pop()
      const time = new Date().getTime()
      const fileName = `photo-editor_${time}.${fileExtension}`

      // Save image to s3 storage
      const media = await Utils.saveImageRemote(fileUrl, fileName, path)
      props.onSave && props.onSave()
      await new Promise((resolve) => setTimeout(resolve, 2000))

      if (useCreatePostStore.getState().activePost != undefined && props.isModal) {
        const crelloMediaType = Utils.isVideo(crelloResponse.extension) ? 'video' : 'image'

        media.url = crelloResponse.url
        media.type = crelloMediaType

        const element = media 
        if ('_id' in activePost && activePost._id === 'GLOBAL') {
          const activePostData = activePost as IPostData
          activePostMedias = activePostData.medias
          updateActive({ medias: [...activePostMedias, element] })
        } else if ('variantId' in activePost) {
          activePostMedias = activePost.data.medias
          updateActive({ medias: [...activePostMedias, element] })
        } else {
          updateActive({ medias: [element] })
        }

        useCreatePostStore.getState().setRefreshMediaContent(true)
      }

      Tracker.trackEvent(TRACKEREVENTS.EXPORT_GRAPHIC_EDITOR_EXPORT_FILE, getEditorFileSaveData(crelloResponse))
    } catch (e) {
      console.error(e)
    }
    useCreatePostStore.getState().setMediaLoading(false)
  }

  const handleOnClickEditor = async () => {
    try {
      const check = await AppStore.checkPermission('media_editor')

      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.useEditor}`)

      check && openEditor([], onCrelloSave)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <OpenEditorButton
      variant="secondary"
      onClick={handleOnClickEditor}
      preventLoading={true}
      ml={24}
      style={{ maxHeight: 40 }}
    >
      <Icons.medias width={28} fill={AppStore.theme.o.on_secondary_container} />
      {T.mediaPage.openEditor}
    </OpenEditorButton>
  )
}

const OpenEditorButton = styled(Button)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  gap: 8px;
`
