/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Text } from 'components'
import { AppStore, T } from 'utils'
import { LabelFilterDraft } from './LabelFilterDraft'
import { RefreshButton } from 'components/RefreshButton'
import { useDraftStore } from 'stores/DraftStore'
import { CreatedFilterDraft } from './CreatedFilterDraft'
import { ContentFilterDraft } from './ContentFilterDraft'
import { isVisible, RemoteConfigValues } from 'utils/CheckVisibleElement'
import { useUserStore } from 'stores/UserStore'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {}

export const DraftHeader = ({}: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */
  const { forceUpdate } = useDraftStore()

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  const { scopes } = useUserStore((s) => ({
    scopes: s.scopes,
  }))

  const isAutopilotEnabled = isVisible(RemoteConfigValues.AutopilotButtonDisabled) || scopes.autopilot

  return (
    <DraftHeaderContainer>
      <Title>{T.draftModal.yourDrafts}</Title>

      <Box gap={24} center row mr={16}>
        <LabelFilterDraft />

        <RefreshContainer variant="neutral">
          <RefreshButton refreshAction={() => forceUpdate()} style={{ width: '100%', padding: '8px' }} />
        </RefreshContainer>

        {isAutopilotEnabled && <CreatedFilterDraft />}

        <ContentFilterDraft />
      </Box>
    </DraftHeaderContainer>
  )
}

const DraftHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const Title = styled(Box)`
  font-size: 24px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.black};
`

const RefreshContainer = styled(Button)`
  min-height: 32px !important;
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`
