/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialUser } from 'components/UI/SocialUser'
import { IPostVariant } from 'types/PostInterface'
import { isProd } from 'utils/CheckVisibleElement'
import { getFirstMedia } from 'utils/PostUtils/Post'
import { FirstComment } from '../GenericExtras/FirstComment'
import { Location } from '../GenericExtras/LocationsComponents/Location'
import { PreviewVideoContainer } from '../GenericExtras/PreviewVideoContainer'
import { Collaboration } from './CollabComponents/Collaboration'
import { Tags } from './Tags'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const InstagramExtrasContainer = ({ post }: Props) => {
  const { picture, provider, name } = post.info.account
  const { medias } = post.data
  const { type } = post.info

  return (
    <InstagramExtrasContainerContainer>
      <SocialUser picture={picture} provider={provider} name={name} />

      <ExtrasContent>
        <FirstComment post={post} />

        <Location post={post} />

        <Collaboration post={post} />

        <Tags post={post} />

        {/* Controlla se nei media c'è un video e siamo nella situazione REEL */}
        {medias && medias.length > 0 && getFirstMedia(medias, 'video') && type === 'STANDARD' && (
          <PreviewVideoContainer post={post} filterVideoUrl={getFirstMedia(medias, 'video')} isReel />
        )}

        {/* Controlla se nei media c'è un video e se è selezionato l'opzione STORY */}
        {medias && medias.length > 0 && getFirstMedia(medias, 'video') && type === 'STORY' && (
          <PreviewVideoContainer post={post} filterVideoUrl={getFirstMedia(medias, 'video')} />
        )}
      </ExtrasContent>
    </InstagramExtrasContainerContainer>
  )
}

const InstagramExtrasContainerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
`

const ExtrasContent = styled(Box)`
  width: 100%;
  max-width: 541px;
  flex-direction: column;
  gap: 24px;
  border: none;
`
